import https from '@/router/https'
//获取发起拼团
export function getStartAfight(id) {
    return https.fetchGet('/mallapp/grouponinfo/'+id)
}
// 去拼单
export function getSharetype(param) {
    return https.fetchGet('/mallapp/grouponuser/page/grouponing',param)
}

