
<template>
  <div
    class="commodity-spike"
    v-if="goodSInfo.goodsSpu && goodSInfo.goodsSku"
    @touchmove.self="() => false"
  >
    <van-nav-bar
      title="发起拼团"
      fixed
      left-text=""
      right-text=""
      left-arrow
      @click-left="$router.back()"
    />
    <!-- <div class="head" @touchmove.prevent>
      <img
        class="back"
        src="../../assets/img/commoditySpike/back.png"
        alt=""
        @click="$router.back()"
      />
      <div class="title">发起拼团</div>
    </div> -->
    <div class="content">
      <div class="box">
        <div class="goods-list">
          <div class="goods-item">
            <div class="title">
              <img
                @click="JumpStore()"
                class="brand-logo"
                width="25px"
                height="25px"
                :src="goodSInfo.shopInfo.imgUrl"
              />
              <span class="store-name">{{ goodSInfo.shopInfo.name }}</span>
            </div>
            <div class="good-desc">{{ goodSInfo.name }}</div>
            <div class="join-poeple">
              <span class="join-rule">{{ goodSInfo.grouponNum }}人团</span>
              <span class="poeple-num"
                >已有{{ goodSInfo.launchNum }}人参加</span
              >

              <span class="rule" @click="openRule()">拼团规则</span>
            </div>
            <div class="good-info">
              <img
                class="good-img"
                :src="goodSInfo.goodsSpu.picUrls[0]"
                alt="商品图片"
              />
              <div class="desc">
                <span class="good-name van-multi-ellipsis--l2">{{
                  goodSInfo.goodsSpu.name
                }}</span>
                <span
                  v-if="goodSInfo.goodsSku.specs && goodSInfo.goodsSku"
                  class="specifications"
                >
                  <span
                    v-for="item in goodSInfo.goodsSku.specs"
                    :key="item.specValueName"
                  >
                    {{ item.specValueName }}
                  </span>
                </span>
                <div class="prices">
                  <span class="activeprice"
                    >￥{{ goodSInfo.grouponPrice }}</span
                  >
                  <span class="orginprice"
                    >￥{{ goodSInfo.goodsSku.salesPrice }}</span
                  >
                  <div class="lable">包邮</div>
                </div>
              </div>
            </div>

            <div class="robbing" @click="showShare = true">去分享</div>
            <div class="time">
              {{ goodSInfo.validBeginTime }}至{{ goodSInfo.validEndTime }}
            </div>
          </div>
          <div class="frends" v-if="joinPoeple.length != 0">
            <div class="title">
              <div class="left"></div>
              已有{{ joinPoeple.length }}个拼单,可直接参与
            </div>
            <div
              class="['hlep-list',{ index!=joinPoeple.length-1}]"
              v-for="(item, index) in joinPoeple"
              :key="index"
            >
              <div class="hlep-item">
                <img class="frends-img" :src="item.headimgUrl" alt />
                <div class="frends-name">
                  {{ item.nickName.slice(0, 3) + "..." }}
                </div>
                <div class="collage-info">
                  <span>{{ timer[index] }}</span>

                  <p>
                    还差
                    <span>{{ item.grouponNum - item.havgrouponNum }}人</span>
                    拼成
                  </p>
                </div>
                <div class="frends-help-money" @click="ShareBill(item.id)">
                  <div>去拼单</div>
                </div>
              </div>
            </div>
          </div>
          <div class="footer" v-if="goodSInfo.goodsSpu.description">
            <div class="title">商品信息</div>
            <div class="richtext" v-html="goodSInfo.goodsSpu.description"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-bottom" @touchmove.prevent>
      <!-- <div class="footer-box">
        <p class="bottom-one" @click="$contact(goodSInfo.imAccount)">
          <span>
            <img src="../../assets/kefu2.png" alt />
          </span>
          <span>客服</span>
        </p>
        <p class="bottom-two">
          <span @click="buy(2)">原价购买</span>
          <span @click="buy(0)">发起拼团</span>
        </p>
      </div> -->
    </div>
    <van-popup v-model="show" position="bottom" :style="{ height: '15%' }">
      <!-- <span class="first-tress" @click="cancel">
          取消
      </span>
      <span class="first-one">
        发送给朋友
      </span>
       <span class="first-two" >
        生成海报
      </span>-->
      <div class="bottom-list">
        <div style="width: 100px" @click="shareInfo(0)">
          <img src="/img/weixin.png" alt style="width: 32px" />
          <p>微信</p>
        </div>
        <div style="width: 100px" @click="shareInfo(4)">
          <img src="/img/pengyouquan.png" alt style="width: 32px" />
          <p>315好友</p>
        </div>
        <div style="width: 100px" @click="shareInfo(2)">
          <img src="/img/qq.png" alt style="width: 32px" />
          <p>QQ</p>
        </div>
      </div>
    </van-popup>
    <div class="rule-info" v-show="isShow" @touchmove.prevent>
      <div class="rule-box fade-out-fwd">
        <div class="rule-title">
          规则说明
          <span class="close" @click="closeRule()">X</span>
        </div>
        <div class="rule-content">{{ goodSInfo.grouponRule }}</div>
      </div>
    </div>
    <van-share-sheet
      v-model="showShare"
      title="立即分享给好友"
      cancel-text=""
      :options="options"
      @select="onSelect"
    />
  </div>
</template>

<script>
import { getStartAfight, getSharetype } from "@/api/StartAfight/StartAfight";
// import { Toast } from 'vant'
export default {
  data() {
    return {
      id: "",
      goodSInfo: {},
      show: false,
      isLoading: false,
      current: 1,
      size: 20,
      isShow: false,
      isBargain: false,
      loading: false,
      finished: false,
      joinPoeple: [],

      timer: [],
      shareClass: { title: "", picurl: "", desc: "" },
      showShare: false,
      options: [
        { name: "微信", icon: "wechat", index: 0 },
        { name: "朋友圈", icon: "wechat-moments", index: 1 },
        { name: "QQ", icon: "qq", index: 2 },
      ],
      form: {
        name: "",
        picUrl: "",
        specValueName: "",
      },
      data: window.navigator.userAgent,
    };
  },

  created() {
    // Toast(navigator.userAgent )
    // if (navigator.userAgent.indexOf("万民") == -1) {
    //   this.openApp();
    //   return;
    // }

    if (navigator.userAgent.indexOf('QQTheme') != -1) {
      this.openApp()
      return
    }
    if (navigator.userAgent.indexOf('MicroMessenger') != -1) {
      this.openApp()
      return
    }

    let session = this.$route.query.session;
    if (session) {
      this.$store.commit("setSession", session);
    }
    this.getUser();
    this.id = this.$route.query.id;
    this.getStartAfightType();
    // this.getSharetypepell()
  },
  activated() {
    // this.$router.go(0)
  },
  methods: {
    buy(type) {
      if (type === 0) {
        if (this.goodSInfo.isBuy - 0) {
          this.$toast("不可重复购买该活动商品");
          return;
        }
        this.data = {
          type: 2,
          price: parseFloat(this.goodSInfo.goodsSku.salesPrice).toFixed(2),
          name: this.goodSInfo.goodsSpu.name,
          subtitle: this.goodSInfo.shareTitle,
          activeprice: parseFloat(this.goodSInfo.grouponPrice).toFixed(2),
          relationId: "",
          skuId: this.goodSInfo.goodsSku.id,
          spuId: this.goodSInfo.goodsSpu.id,
          quantity: 1,
          paymentWay: 2,
          deliveryWay: 1,
          img: this.goodSInfo.goodsSpu.picUrls[0],
          markedId: this.goodSInfo.id,
        };
      } else {
        this.data = {
          type: 0,
          price: parseFloat(this.goodSInfo.goodsSku.salesPrice).toFixed(2),
          name: this.goodSInfo.goodsSpu.name,
          subtitle: this.goodSInfo.shareTitle,
          activeprice: "",
          relationId: this.goodSInfo.id,
          skuId: this.goodSInfo.goodsSku.id,
          spuId: this.goodSInfo.goodsSpu.id,
          quantity: 1,
          img: this.goodSInfo.goodsSpu.picUrls[0],
          paymentWay: 2,
          deliveryWay: 1,
          markedId: this.goodSInfo.id,
        };
      }

      this.$router.push({
        path: "/activeOrder",
        query: {
          data: JSON.stringify(this.data),
        },
      });
    },
    onSelect(index) {
      console.log(index.index);

      this.shareInfo({
        title: this.goodSInfo.shareTitle,
        url:
          this.goodSInfo.goodsSpu.picUrls[0] ||
          "https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPnge075b8c61cca1d61d6fd515ef1b8fe29671548b08ff1d214b42b6e4dded6c95e",
        num: index.index,
        openUrl: "",
      });
      this.showShare = false;
    },
    getStartAfightType() {
      getStartAfight(this.id).then((res) => {
        if (res.data.code === 0) {
          this.goodSInfo = res.data.data;
          console.log(this.goodSInfo, "sha");
          getSharetype({
            current: this.current,
            size: this.size,
            grouponId: this.goodSInfo.id,
            isLeader: "1",
            status: "0",
          }).then((res) => {
            if (res.data.code === 0) {
              console.log(res, "拼团");
              //  this.joinPoeple=res.data.data.records
              // this.joinPoeple=res.data.data.records
              this.joinPoeple = res.data.data.records.filter((item) => {
                item.validEndTime = item.validEndTime.replace(/-/gi, "/");
                return new Date(item.validEndTime).getTime() - Date.now() > 0;
              });
              console.log(this.joinPoeple);
              this.joinPoeple.forEach((item, index) => {
                this.getCJS(item.validEndTime, index);
              });
            }
          });
        }
      });
    },
    Original() {
      this.$router.push({
        path: "/sureorder",
        query: {
          type: 1,
          id: this.$route.query.id,
        },
      });
    },
    grouptype() {
      this.$router.push({
        path: "/sureorder",
        query: {
          id: this.$route.query.id,
          type: 0,
        },
      });
    },
    // shareInfo(type) {

    //   this.shareClass.title = this.title;
    //   this.shareClass.picurl = this.picurl;
    //   this.shareClass.desc = this.desc;
    //   let u = navigator.userAgent;
    //   let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
    //   let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    //   if (isIOS) {
    //     window.webkit.messageHandlers.share.postMessage(
    //       JSON.stringify(this.shareClass)
    //     );
    //   }
    //   if (isAndroid) {
    //     window.android.shareImg(type,this.shareClass);
    //   }
    // },

    onLoad() {
      this.current++;
    },

    ShareBill(id) {
      //  if( (this.goodSInfo.isBuy-0) ){
      //       this.$toast('不可重复购买该活动商品')
      //       return
      //   }

      this.$router.push({
        path: "/goCollage",
        query: { id: id, session: this.$store.state.session },
      });
    },

    getCJS(timestamp, num) {
      console.log(timestamp);
      var nowTime = Date.now();
      var endTime = new Date(timestamp);
      var t = endTime.getTime() - nowTime;
      if (t < 1) {
        for (let i = 0; i < this.joinPoeple.length; i++) {
          clearInterval(i);
        }
        this.$rotuer.go(0);
      }
      // var day = Math.floor(t / 1000 / 60 / 60 / 24);
      var hour = Math.floor((t / 1000 / 60 / 60) % 24);
      var min = Math.floor((t / 1000 / 60) % 60);
      var sec = Math.floor((t / 1000) % 60);
      if (hour < 10) {
        if (hour < 1) {
          hour = "";
        } else {
          hour = hour + "时";
        }
      } else {
        hour = hour + "时";
      }
      if (min < 10) {
        if (min < 1) {
          if (hour != "") {
            min = "00分";
          } else {
            min = "";
          }
        } else {
          min = "0" + min + "分";
        }
      } else {
        min = min + "分";
      }
      if (sec < 10) {
        if (sec < 1) {
          if (min != "") {
            sec = "00秒";
          } else {
            sec = "";
          }
        } else {
          sec = "0" + sec + "秒";
        }
      } else {
        sec = sec + "秒";
      }

      this.$set(this.timer, num, hour + min + sec);
      setInterval(() => {
        var nowTime = Date.now();
        var endTime = new Date(timestamp);
        var t = endTime.getTime() - nowTime;
        if (t < 1) {
          this.$rotuer.go(0);
        }
        // var day = Math.floor(t / 1000 / 60 / 60 / 24);
        var hour = Math.floor((t / 1000 / 60 / 60) % 24);
        var min = Math.floor((t / 1000 / 60) % 60);
        var sec = Math.floor((t / 1000) % 60);
        if (hour < 10) {
          if (hour < 1) {
            hour = "";
          } else {
            hour = hour + "时";
          }
        } else {
          hour = hour + "时";
        }

        if (min < 10) {
          if (min < 1) {
            if (hour != "") {
              min = "00分";
            } else {
              min = "";
            }
          } else {
            min = "0" + min + "分";
          }
        } else {
          min = min + "分";
        }

        if (sec < 10) {
          if (sec < 1) {
            if (min != "") {
              sec = "00秒";
            } else {
              sec = "";
            }
          } else {
            sec = "0" + sec + "秒";
          }
        } else {
          sec = sec + "秒";
        }

        this.$set(this.timer, num, hour + min + sec);
        // console.log(this.timer[num]);
      }, 1000);
    },

    // setHtml2canvas: function () {
    //   let that = this;
    //   let canvasBox = that.$refs.poster;
    //   html2canvas(canvasBox,{ useCORS: true,allowTaint: false,}).then(function (canvas){
    //     if(that.canvasStatus) {
    //       return
    //     }
    //     that.posterImage = canvas.toDataURL();
    //     that.canvasStatus = true;
    //     that.$dialog.loading.close();
    //   }).catch(err =>{
    //     console.log(err)
    //   })
    // },
    // savePosterPath(){
    //    this.$dialog.loading.open();
    //    this.setHtml2canvas();
    // },
    // getTime(index) {
    //   if (index === this.active) {
    //     return;
    //   }
    //   console.log(index);
    //   this.active = index;
    //   this.getActive;
    // },
    Share() {
      this.show = true;
    },
    cancel() {
      this.show = false;
    },
    getId() {
      //   获取用户点击的商品id 并跳转到相应的页面
      //   this.$router.push("/bargainDatil");
    },
    JumpStore() {
      //获取店铺id 并跳转到店铺首页
    },
    closeRule() {
      this.isShow = false;
    },
    openRule() {
      this.isShow = true;
    },
    setRem() {
      let screenWidth = document.documentElement.getBoundingClientRect().width;
      console.log(111);
      document.documentElement.style.fontSize = screenWidth / 10 + "px";
    },
  },
  mounted() {
    this.setRem();
    window.addEventListener("orientationchange", this.setRem);
    window.addEventListener("resize", this.setRem);
  },

  beforeDestroy() {
    document.documentElement.style.fontSize = "16px";
    this.timer.forEach((item) => {
      clearInterval(item);
    });
  },
};
</script>

<style lang="less" scoped>
/deep/ .van-share-sheet__options {
  display: flex;
  justify-content: space-between;
}
.commodity-spike {
  height: 100vh;
  width: 100%;
  box-sizing: border-box;
  background-color: #f6f6f6;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
}
// .head {
//   position: fixed;
//   left: 0;
//   top: 0;
//   width: 100%;
//   padding-top: .266667rem;
//   background-color: white;
//   padding-bottom: 0.266667rem;
//   border-bottom: 1px solid #ccc;
//   z-index: 10000;
// }
// .head .back {
//   width: 0.666667rem;
//   height: 0.64rem;
//   float: left;
//   margin-left: 0.533333rem;
// }
.first-one {
  display: block;
  height: 37px;
  background: url("../../assets/qupintuan.png") no-repeat center;
  background-size: 100%;
  margin: 15px 16px 0 16px;
  font-size: 15px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.first-two {
  display: block;
  height: 0.493333rem;
  border-radius: 0.666667rem;
  background-color: green;
  margin: 10px 16px 0 16px;
  font-size: 15px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.first-tress {
  display: block;
  // border: 1px solid red;
  height: 40px;
  color: red;
  line-height: 40px;
  padding-right: 16px;
  text-align: right;
  font-size: 12px;
  background-color: #fff;
}
.head .title {
  float: right;
  margin-right: 50%;
  transform: translateX(50%);
  font-size: 0.453333rem;
  font-weight: 600;
}
.commodity-spike {
  position: relative;
  box-sizing: border-box;
}
.content {
  padding: 2.333333rem 0 0;
  width: 100%;
  left: 0;
  top: 1.5rem;
  overflow: auto;
  box-sizing: border-box;
  height: 100%;
  padding-bottom: constant(safe-area-inset-bottom); /*兼容 IOS<11.2*/
  padding-bottom: env(safe-area-inset-bottom); /*兼容 IOS>11.2*/
}
.content::-webkit-scrollbar {
  display: none;
}

.content .list {
  display: flex;
  margin: auto;
  align-items: center;
  color: white;
  margin-bottom: 0.293333rem;
  overflow-x: auto;
  box-sizing: border-box;
}
.box {
  padding-bottom: 2rem;
  box-sizing: border-box;
}
.remainning-time {
  margin: 0.693333rem 0 1.12rem 0.853333rem;
  height: 0.56rem;
  font-size: 0.4rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 0.56rem;
}
.remainning-time span {
  color: #ff5200ff;
}
.goods-item {
  background-color: white;
  border-radius: 0.133333rem;
  margin: 0 0.253333rem 0.266667rem;
  padding: 0.373333rem 0.426667rem;
  box-sizing: border-box;
  border-radius: 0.133333rem;
}
.goods-item .title {
  display: flex;
  align-items: center;
}
.title .brand-logo {
  width: 0.666667rem;
  height: 0.666667rem;
}
.title .store-name {
  font-size: 0.4rem;
  font-weight: 600;
  padding-left: 0.266667rem;
}
.good-desc {
  height: 0.56rem;
  font-size: 0.4rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 0.56rem;
  margin-top: 0.24rem;
  margin-bottom: 0.266667rem;
}
.good-info {
  display: flex;
  font-size: 0.4rem;
}
.good-info .good-img {
  width: 2.426667rem;
  height: 2.426667rem;
  box-sizing: border-box;
  margin-right: 0.266667rem;
  border-radius: 0.266667rem;
}
.desc {
  display: flex;
  flex-direction: column;
}
.desc .good-name {
  min-height: 1.12rem;
  font-size: 0.4rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 0.56rem;
  margin-bottom: 0.186667rem;
}
.desc .activeprice {
  color: #a80000;
  font-weight: 600;
  margin-right: 0.266667rem;
}
.specifications {
  flex: 1;
  font-size: 0.32rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 0.453333rem;
  margin-bottom: 0.106667rem;
}
.desc .orginprice {
  color: #999;
  text-decoration: line-through;
  font-weight: 600;
}
.desc .lable {
  padding: 0 0.066667rem;
  background-color: #ff5200ff;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(0.83);
  color: white;
  font-size: 0.32rem;
  display: inline-block;
  margin-left: 0.266667rem;
}
.join-poeple {
  display: flex;
  align-items: center;
  margin-bottom: 0.373333rem;
}
.join-rule {
  font-size: 0.32rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.066667rem;
  border: 1px solid #ff5200ff;
  margin-right: 0.266667rem;
  text-align: center;
  color: #ff5200ff;
}
.poeple-num {
  height: 0.453333rem;
  font-size: 0.32rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999;
}
.rule {
  height: 0.453333rem;
  font-size: 0.32rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #005bff;
  line-height: 0.453333rem;
  flex: 1;
  text-align: right;
  // padding-right: .426667rem;
}
.robbing {
  width: 90%;
  height: 0.986667rem;
  text-align: center;
  line-height: 0.986667rem;
  color: white;
  font-size: 0.4rem;
  font-weight: 600;
  background-image: url("../../assets/img/commoditySpike/btn.png");
  background-position: center top;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin: 0.533333rem auto 0.266667rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.time {
  width: 6.72rem;
  height: 0.453333rem;
  font-size: 0.32rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 0.453333rem;
  text-align: center;
  width: 100%;
}

.bargain-info {
  padding: 0.4rem 0.853333rem 0;
  font-size: 0.4rem;
}
.bargain-info .bargain-data {
  height: 0.56rem;
  font-size: 0.4rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 0.56rem;
}
.prices {
  display: flex;
  align-items: center;
}
.bargain-info .bargain-data span {
  color: #ff5200ff;
  height: 0.56rem;
  font-size: 0.4rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;

  line-height: 0.56rem;
}
.proportion {
  width: 100%;
  margin-top: 0.533333rem;
  height: 0.4rem;
  border-radius: 0.213333rem;
  background: #eaeef5;
}
.readyhave {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: white;

  font-size: 0.32rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 0.453333rem;
}
.proportion-item {
  position: relative;
  width: 50%;
  height: 100%;
  background-color: #e64d42ff;
  border-radius: 0.213333rem;
  overflow: hidden;
}
.frends {
  padding: 0.646667rem 0.3333rem 0.8rem 0.6rem;
  box-sizing: border-box;
  margin: 0 0.253333rem;
  border-radius: 0.133333rem;
  background-color: white;
}
.frends .title {
  display: flex;
  align-items: center;
  font-size: 0.4rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 0.56rem;
  margin-bottom: 0.373333rem;
}
.frends .left {
  width: 3px;
  height: 0.666667rem;
  background-color: #e64d42ff;
  border-radius: 0.133333rem;
  margin-right: 0.66667rem;
}
.hlep-item {
  display: flex;
  align-items: center;
  padding: 0.373333rem 0;
}
.border {
  border-bottom: 1px solid #d8d8d8;
}
.frends-name {
  font-size: 0.4rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 0.56rem;
}
.collage-info {
  font-size: 0.32rem;
  color: #e64d42ff;
  float: right;
  flex: 1;
  text-align: right;
  padding-right: 0.266667rem;
}
.collage-info span {
  margin-bottom: 0.266667rem;
  color: #e64d42ff;
}
.collage-info p {
  color: black;
  padding-top: 0.133333rem;
}
.collage-info p span {
  color: #e64d42ff;
}
.frends-help-money {
  text-align: right;
  color: #e64d42ff;
  font-size: 0.16rem;
  font-weight: 600;
}
.frends-help-money div {
  width: 1.36rem;
  height: 0.693333rem;
  font-size: 0.32rem;
  background: #e64d42ff;
  // margin-right: 0.426667rem;
  color: white;
  border-radius: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.4rem;
}

.frends-img {
  width: 1.333333rem;
  height: 1.333333rem;
  border-radius: 50%;
  margin-right: 0.266667rem;
}
.footer {
  text-align: center;
  background-color: white;
  border-radius: 0.133333rem;
  padding-top: 0.4rem;
  margin: 0.266667rem 0.253333rem 0 0.253333rem;
}
.footer .title {
  height: 0.56rem;
  font-size: 0.4rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 0.56rem;
  margin-bottom: 0.4rem;
}
.richtext * {
  width: 100%;
  padding: 0 0.426667rem;
  box-sizing: border-box;
}
.rule-info {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;

  display: flex;
  z-index: 1000;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}
.rule-box {
  font-size: 0.426667rem;
  background-color: white;
  width: 80%;
  border-radius: 0.133333rem;
  overflow: hidden;
  z-index: 2000;
}
.rule-title {
  text-align: center;
  padding: 0.266667rem 0;
  background-color: white;
}
.rule-title span {
  float: right;
  margin-right: 0.533333rem;
  color: #ff7200ff;
}
.rule-content {
  height: 100%;
  width: 100%;

  background-color: #ccc;
  box-sizing: border-box;
  box-sizing: border-box;
  padding: 0.266667rem 10%;
  white-space: pre-wrap;
}

/* 
动画
*/
.fade-out-fwd {
  -webkit-animation: fade-out-fwd 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: fade-out-fwd 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes fade-out-fwd {
  0% {
    -webkit-transform: translateZ(100);
    transform: translateZ(100);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(80px);
    transform: translateZ(80px);
    opacity: 1;
  }
}
@keyframes fade-out-fwd {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(80px);
    transform: translateZ(80px);
    opacity: 1;
  }
}

.footer-bottom {
  box-shadow: 0px 0px 0.133333rem 0px rgba(0, 0, 0, 0.2);
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: white;
  box-sizing: border-box;
  z-index: 100;
  padding: 0 0.426667rem 0 0.666667rem;

  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  .footer-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.133333rem 0;
  }
  .bottom-one {
    font-size: 0.32rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      width: 0.666667rem;
      height: 0.666667rem;
    }
  }
  .bottom-two {
    height: 100%;
    display: flex;
    font-size: 0.373333rem;
    border-radius: 0.266667rem;
    justify-content: flex-end;
    align-items: center;
    :first-child {
      display: block;
      width: 3.786667rem;
      height: 0.96rem;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      border-radius: 0.266667rem 0 0 0.266667rem;
      background-color: #313131;
    }
    :last-child {
      display: block;
      width: 3.786667rem;
      height: 0.96rem;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      border-radius: 0 0.266667rem 0.266667rem 0;
      background-color: #ff3c00;
    }
  }
}
.bottom-list {
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
}
img {
  margin: 0;
  padding: 0;
}
</style>